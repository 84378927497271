































import { Component } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
// import { AppStoreModule } from '@/store/modules/app';
// import DEFAULT_CHART_DATA from '@topo/assets/data/chart-data.json';
// import CHART_GAUGE_CAR_DASHBOARD from '@topo/assets/data/chart-gauge-car-dashboard';
// import CUSTOM_CHART_OPTIONS from '@topo/assets/data/chart-gauge-custom';
import TopoRender from '@topo/components/topo/topo-render.vue';

@Component({ components: { TopoRender } })
export default class DashboardIndexComponent extends BaseComponent {
    // get ChartData() {
    //     return DEFAULT_CHART_DATA.chartData;
    // }
    // get ChartSettings() {
    //     return DEFAULT_CHART_DATA.chartSettings;
    // }
    // get ChartGaugeData() {
    //     return CHART_GAUGE_CAR_DASHBOARD.chartData;
    // }
    // get ChartGaugeSettings() {
    //     return CHART_GAUGE_CAR_DASHBOARD.chartSettings;
    // }
    // chartOptions = CUSTOM_CHART_OPTIONS;
    mounted() {
        // @ts-ignore
        const scriptElement = document.createElement('script');
        scriptElement.src = '//rf.revolvermaps.com/0/0/6.js?i=5kku3vzyera&amp;m=0&amp;c=ff0000&amp;cr1=ffffff&amp;f=arial&amp;l=1&amp;s=341';
        scriptElement.async = true;
        document.getElementById('visitors').appendChild(scriptElement);

        // if (this.interval) {
        //     clearInterval(this.interval);
        //     this.interval = null;
        // }
        // this.interval = setInterval(() => {
        //     var nextSource = [[1, Math.round(Math.random() * 100)]];
        //     this.chartOptions.dataset.source = nextSource;
        // }, 3000);

        // (this.$refs.topoRender as TopoRender).initRender('parking_totoal_data');
    }
    // get CurrentPageName(): string | null {
    //     if (!AppStoreModule.CurrentPageName) {
    //         return null;
    //     }
    //     return `${AppStoreModule.CurrentPageName}, `;
    // }
}
