var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dashboard-index-component" }, [
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c(
        "section",
        {
          staticClass: "widget widget_tag_cloud wrapper-md clear",
          attrs: { id: "tag_cloud-2" }
        },
        [
          _c("div", {
            staticClass: "tags l-h-2x",
            staticStyle: { width: "auto", height: "400px" },
            attrs: { id: "visitors" }
          })
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }